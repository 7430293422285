@import "../../variables";

.services-container {
  padding: 0;
  
  .container {
    padding-inline: 40px;
    .row,
    .row-1,
    .row-2 {
      display: flex;
      gap: 20px;
      align-items: flex-start;
    }

    .row {
      .row-1 {
        .title-pattern {
          height: 90px;
          width: 20px;
        }
      }

      .row-2 {
        .title-pattern {
          height: 30px;
          width: 20px;
        }
      }
    }

    > .row {
      padding-top: 100px;
      padding-bottom: 30px;
      gap: 70px;

      .img-container {
        width: 50%;
        border-radius: 24px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .text-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        p {
          font-size: 18px;
          line-height: 30px;
        }

        .row {
          gap: 20px;
          align-items: center;

          h1 {
            color: $primary_color;
            font-size: 32px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 810px) {
  .services-container {
    .container {
      padding-block: 100px;
    padding-inline: 20px;

      > .row {
        padding-block: 0;
        padding-block: 25px;

        flex-direction: column;

        .img-container {
          width: 100%;
        }
        .text-content {
          width: 100%;
        }
      }
      .img-left-service {
        flex-direction: column-reverse;
      }
    }
  }
}
