$primary_color: #DC5932;
$light_orange: #F3AB96;
$secondary_color: #FDF7F5;
$brown_color: #802C3B;
$white_color: #FFFFFFFF;
$black_color: #000000;


// gap
$gap: 10px;
$medium_gap: 16px;
$big_gap: 24px;

// radius
$big_radius: 32px;
$medium_radius: 12px;
$small_radius: 8px;