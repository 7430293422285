@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Quicksand", sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  padding: 12px 24px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
}

.primary-button {
  background-color: #DC5932;
  border-radius: 32px;
  color: white;
}

.container {
  max-width: 1200px;
  padding: 0;
  margin: auto;
}

.hero {
  background-color: #FDF7F5;
  height: 90vh;
  max-width: 100%;
}

.row {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.vertical-pattern {
  border-radius: 32px 0px;
}

.title-pattern {
  width: 24px;
  height: 62px;
  border-radius: 32px 0;
  background: rgb(93, 56, 45);
  background: linear-gradient(360deg, rgb(93, 56, 45) 10%, rgb(234, 155, 132) 90%);
}

nav {
  background-color: #FDF7F5;
  padding: 20px 0;
}
nav .container {
  padding-inline: 20px;
}
nav .container .row {
  justify-content: space-between;
}
nav .links {
  display: flex;
  align-items: center;
  gap: 24px;
}
nav .links li a {
  color: #000000;
  font-weight: bold;
}
nav .links li .active {
  color: #DC5932;
}
nav .links li .underline {
  width: 100%;
  height: 2px;
  background-color: #DC5932;
}
nav .links li .contact-btn {
  background-color: #DC5932;
  padding: 14px 40px;
  border-radius: 32px;
  color: white;
}
nav .menu-bar {
  display: none;
}
nav .menu-bar button {
  background: transparent;
  border: transparent;
}

.mobile-menu {
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  z-index: 20;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.mobile-menu .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FDF7F5;
  padding: 16px 20px;
}
.mobile-menu .top .logo img {
  width: 54px;
}
.mobile-menu .top .menu-bar {
  display: block;
}
.mobile-menu .top .menu-bar button {
  font-size: 24px;
}
.mobile-menu .links-container {
  text-align: center;
}
.mobile-menu .links-container .mobile-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-inline: 20px;
}
.mobile-menu .links-container .mobile-links li {
  background-color: #fcf9f9;
  padding: 16px 50px;
  border-radius: 8px;
}
.mobile-menu .links-container .mobile-links li a {
  color: #000;
}
.mobile-menu .links-container .mobile-links li .active {
  color: #DC5932;
}

.page-header {
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(56, 19, 9) 40%);
  color: #DC5932;
  text-align: center;
  padding: 48px;
}
.page-header h3 {
  font-size: 32px;
}
.page-header h4 {
  color: white;
}
.page-header .facility-services-desc {
  color: white;
}

footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(56, 19, 9) 40%);
}
footer .container {
  color: white;
  padding: 20px;
}
footer .container .footer-row {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 150px;
}
footer .container .footer-row .col {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
footer .container .footer-row .col h2 {
  font-size: 18px;
}
footer .container .footer-row .col .facebook-link {
  background-color: #DC5932;
  color: white;
  padding: 24px 32px;
  border-radius: 14px;
}
footer .container .footer-row .col .links {
  display: flex;
  gap: 24px;
}
footer .container .footer-row .col .links .link {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
footer .container .footer-row .col .links .link a {
  color: white;
}
footer .container .footer-row .col .col-row {
  display: flex;
  gap: 24px;
}
footer .container .footer-row .col .col-row .icon {
  font-size: 24px;
}
footer .container .footer-row .col .col-row-2 {
  display: flex;
  gap: 24px;
  align-items: center;
}
footer .container .footer-row .col .col-row-2 .icon {
  font-size: 24px;
}
footer .container .footer-row .col .col-row-2 .desc {
  display: flex;
  flex-direction: column;
}
footer .container .footer-row .col .col-row-2 .desc p {
  font-size: 14px;
}
footer .underline {
  background-color: rgba(87, 87, 87, 0.3215686275);
  width: 100%;
  height: 2px;
}
footer .copyright {
  padding-inline: 20px;
  padding-bottom: 20px;
}
footer .copyright p {
  max-width: 1200px;
  margin: auto;
  color: white;
}

@media screen and (max-width: 1024px) {
  footer .container .footer-row {
    gap: 50px;
  }
}
@media screen and (max-width: 768px) {
  nav .container .links {
    display: none;
  }
  nav .container .menu-bar {
    display: block;
  }
  nav .container .menu-bar button {
    font-size: 32px;
  }
  footer .container .footer-row {
    display: flex;
    flex-wrap: wrap;
  }
  footer .container .footer-row .col .facebook-link {
    padding: 24px 6px;
    text-align: center;
  }
  footer .copyright p {
    font-size: 14px;
  }
}/*# sourceMappingURL=main.css.map */