@import "../../variables";

.hero {
    padding: 10px 20px;

    .container {
        .row {
            gap: 50px;

            .col {
                .pattern {
                    background-color: $primary_color;
                    width: 100px;
                    height: 20px;
                    border-radius: 32px 0;
                }

                h1 {
                    color: $primary_color;
                    font-weight: bold;
                    font-size: 40px;
                }

                .contact-link {
                    background-color: $primary_color;
                    border: transparent;
                    border-radius: 32px;
                    color: $white_color;
                    padding: 14px 56px;
                }
            }

            .col-1 {
                display: flex;
                flex-direction: column;
                gap: 40px;
                width: 40%;
                padding-top: 90px;
            }

            .col-2 {
                width: 60%;

                .hero-1 {
                    position: relative;
                    z-index: 20;
                    left: 0;

                    img {
                        position: absolute;
                        border-radius: 24px;
                        width: 210px;
                        z-index: 10;
                    }

                    .pattern-1 {
                        position: absolute;
                        top: 60px;
                        left: 120px;
                        background-color: $primary_color;
                        width: 108px;
                        height: 115px;
                        border-radius: 32px;
                        z-index: 0;
                    }
                }

                .hero-2 {
                    position: relative;
                    top: 80px;
                    left: 100px;
                    z-index: 10;

                    img {
                        border-radius: 24px;
                        width: 500px;
                    }
                }

                .hero-3 {
                    position: relative;
                    top: 300px;
                    right: 10px;
                    z-index: 15;

                    img {
                        position: absolute;
                        bottom: 150px;
                        border-radius: 24px;
                        width: 250px;
                        right: 30px;
                    }

                    .pattern-2 {
                        position: absolute;
                        background-color: $primary_color;
                        width: 108px;
                        height: 130px;
                        bottom: 240px;
                        right: 190px;
                        border-top-left-radius: 32px;
                    }
                }

                .hero-4 {
                    position: relative;

                    .hero-pattern {
                        position: absolute;
                        background-color: #ea9b84b2;
                        width: 310px;
                        height: 650px;
                        top: -390px;
                        right: 0;
                        border-radius: 0 120px;
                    }
                }
            }
        }
    }
}

.emergency-medical-transport {
    padding: 250px 20px;

    .container {
        .row {
            gap: 80px;

            .col {
                gap: $big_gap;

                .title {
                    display: flex;
                    align-items: center;
                    gap: $big_gap;

                    h1 {
                        color: $primary_color;
                        font-size: 32px;
                    }
                }

                .about-us-link {
                    background-color: $primary_color;
                    width: 50%;
                    text-align: center;
                    color: $white_color;
                    border-radius: $big_radius;
                    padding: 16px 40px;
                    display: flex;
                    align-items: center;
                    gap: $gap;
                }
            }

            .col-2 {
                width: 40%;
            }

            .col-1 {
                width: 50%;
                position: relative;

                .img-1 {
                    position: absolute;
                    left: 0;
                    z-index: 10;
                    top: -250px;

                    img {
                        border-radius: 12px;
                        object-fit: cover;
                        width: 290px;
                        height: 420px;
                    }
                }

                .img-2 {
                    position: absolute;
                    bottom: 8px;
                    z-index: 20;
                    left: 180px;
                    top: -180px;

                    img {
                        border-radius: 12px;
                        object-fit: cover;
                        width: 270px;
                        height: 400px;
                    }
                }

                .col-pattern {
                    position: absolute;
                    left: 150px;
                    top: -300px;

                    .pattern {
                        background-color: #ea9b8466;
                        width: 180px;
                        height: 600px;
                        border-radius: 0 120px;
                    }
                }
            }
        }
    }
}

.assurance {
    padding: 24px 20px;

    .container {
        position: relative;
        z-index: 1;
        background: linear-gradient(90deg, rgba(128, 44, 59, 0.6) 0%, rgba(0, 0, 0, 0.618) 90%),
            url("/public/images/service.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 30px;
        min-height: 100px;
        border-radius: 24px;

        p {
            color: $white_color;
            text-align: center;
            font-size: 32px;
            font-weight: bold;
            position: relative;
            z-index: 2;
        }
    }
}

.services {
    padding: 50px 20px;

    .container {
        display: flex;
        flex-direction: column;
        gap: 100px;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $big_gap;
        }

        .row {
            gap: 45px;

            .col {
                gap: $big_radius;

                .col-title {
                    display: flex;
                    gap: $big_gap;
                }

                img {
                    width: 100%;
                    height: 450px;
                    object-fit: cover;
                    border-radius: $medium_radius;
                }
            }
        }
    }
}

.facility-services {
    padding: 24px 20px;

    .container {
        background: linear-gradient(90deg, rgba(128, 44, 59, 0.6) 0%, rgba(0, 0, 0, 0.466) 90%),
            url("/public/images/MediRydeVans.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: $white_color;
        padding: 90px;
        border-radius: $medium_radius;

        .col {
            gap: $medium_gap;
            text-align: center;
            align-items: center;

            .link {
                padding-top: 16px;

                a {
                    background-color: $primary_color;
                    border-radius: $big_radius;
                    padding: 16px 50px;
                    color: $white_color;
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    gap: 10px;
                }
            }
        }
    }
}

.why-section {
    padding: 150px 20px;

    .container {
        .row {
            gap: 60px;
            align-items: start;

            .col {
                gap: $big_gap;

                img {
                    object-fit: cover;
                    border-radius: $medium_radius;
                }

                .title {
                    display: flex;
                    align-items: center;
                    gap: $big_radius;

                    h1 {
                        color: $primary_color;
                        font-size: 40px;
                    }
                }
            }
        }
    }
}

// responsiveness
@media screen and (max-width: 1024px) {
    .hero {
        .container {
            .row {
                .col-2 {
                    .hero-2 {
                        img {
                            width: 400px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 834px) {
    .hero {
        height: fit-content;
        padding: 80px 20px;

        .container {
            .row {
                flex-direction: column;
                gap: 60px;

                .col {
                    .contact {
                        text-align: center;
                    }
                }

                .col-1 {
                    width: 100%;
                }

                .col-2 {
                    width: 100%;

                    .hero-1 {
                        img {
                            top: 10px;
                            max-width: 100%;
                        }
                    }

                    .hero-2 {
                        img {
                            max-width: 100%;
                        }
                    }

                    .hero-3 {
                        img {
                            right: 100px;
                        }

                        .pattern-2 {
                            right: 250px;
                        }
                    }

                    .hero-4 {
                        .hero-pattern {
                            top: -300px;
                            height: 550px;
                        }
                    }
                }
            }
        }
    }

    .services {
        .container {
            .row {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: $medium_gap;

                .col {
                    .col-title {
                        gap: $medium_radius;

                        h2 {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 810px) {
    .hero {

        .container {
            .row {

                .col-1 {
                    padding-top: 0;
                }

                .col-2 {
                    display: none;

                    .hero-1 {
                        img {
                            top: 10px;
                            max-width: 100%;
                        }
                    }

                    .hero-2 {
                        img {
                            left: 0;
                        }
                    }

                    .hero-3 {
                        img {
                            right: 100px;
                        }

                        .pattern-2 {
                            right: 250px;
                        }
                    }

                    .hero-4 {
                        .hero-pattern {
                            top: -300px;
                            height: 550px;
                        }
                    }
                }
            }
        }
    }

    .emergency-medical-transport {
        margin-bottom: 250px;
        padding: 150px 20px;

        .container {
            .row {
                flex-direction: column-reverse;
                gap: 60px;

                .col-2 {
                    width: 100%;
                }

                .col-1 {
                    width: 100%;
                    margin-top: 50px;

                    .img-1 {
                        top: -80px;

                        img {
                            max-height: 100%;
                        }
                    }

                    .img-2 {
                        top: -5px;
                    }

                    .col-pattern {
                        top: -100px;
                    }
                }
            }
        }
    }

    .why-section {
        padding: 50px 20px;

        .container {
            .row {
                flex-direction: column-reverse;

                .col {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .services {
        .container {
            gap: 30px;

            .row {
                .col {
                    gap: $medium_gap;
                    img {
                        height: 100%;
                    }

                    .col-title {
                        align-items: center;
                        h2 {
                            font-size: 18px;
                        }
                    }

                }

                .col-reverse {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
        }
    }

    .emergency-medical-transport {

        .container {
            .row {
                .col {
                    .title {
                        h1 {
                            font-size: 24px;
                        }
                    }

                    .about-us-link {
                        width: 80%;
                    }
                }

                .col-1 {
                    .img-1 {
                        img {
                            width: 200px;
                        }
                    }

                    .img-2 {
                        top: 80px;

                        img {
                            width: 100%;
                            height: 300px;
                        }
                    }

                    .col-pattern {
                        .pattern {
                            height: 500px;
                            width: 150px;
                        }
                    }
                }
            }
        }
    }

    .facility-services {
        padding: 100px 20px;

        .container {
            padding: 50px;
        }
    }

    .why-section {
        .container {
            .row {
                .col {
                    .title {
                        h1 {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}