.faqs .container {
  padding-top: 100px;
  padding-bottom: 30px;
  padding-inline: 50px;
}
.faqs .container .accordion .main-accordion {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}
.faqs .container .accordion .main-accordion ul {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.faqs .container .accordion .main-accordion ul > div {
  width: 100%;
}
.faqs .container .accordion .main-accordion ul li {
  display: flex;
  gap: 30px;
  cursor: pointer;
  align-items: center;
  width: 100%;
}
.faqs .container .accordion .main-accordion ul li h1 {
  font-size: 18px;
  font-weight: 500;
}
.faqs .container .accordion .main-accordion ul li .plus-icon,
.faqs .container .accordion .main-accordion ul li .minus-icon {
  height: 30px;
  width: 30px;
  display: block;
  cursor: pointer;
}
.faqs .container .accordion .main-accordion .accordion-content {
  width: 50%;
}

.accordion-active {
  color: #DC5932;
}

.accordion-content-mobile {
  display: none;
}

.accordion-content-desktop {
  display: block;
}

@media screen and (max-width: 810px) {
  .faqs .container {
    padding-inline: 20px;
  }
  .faqs .container .accordion .main-accordion ul {
    width: 100%;
  }
  .faqs .container .accordion .main-accordion ul li h1 {
    font-size: 16px;
  }
  .accordion-content-mobile {
    display: block;
  }
  .accordion-content-desktop {
    display: none;
  }
}/*# sourceMappingURL=faq.css.map */