.hero {
  padding: 10px 20px;
}
.hero .container .row {
  gap: 50px;
}
.hero .container .row .col .pattern {
  background-color: #DC5932;
  width: 100px;
  height: 20px;
  border-radius: 32px 0;
}
.hero .container .row .col h1 {
  color: #DC5932;
  font-weight: bold;
  font-size: 40px;
}
.hero .container .row .col .contact-link {
  background-color: #DC5932;
  border: transparent;
  border-radius: 32px;
  color: white;
  padding: 14px 56px;
}
.hero .container .row .col-1 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 40%;
  padding-top: 90px;
}
.hero .container .row .col-2 {
  width: 60%;
}
.hero .container .row .col-2 .hero-1 {
  position: relative;
  z-index: 20;
  left: 0;
}
.hero .container .row .col-2 .hero-1 img {
  position: absolute;
  border-radius: 24px;
  width: 210px;
  z-index: 10;
}
.hero .container .row .col-2 .hero-1 .pattern-1 {
  position: absolute;
  top: 60px;
  left: 120px;
  background-color: #DC5932;
  width: 108px;
  height: 115px;
  border-radius: 32px;
  z-index: 0;
}
.hero .container .row .col-2 .hero-2 {
  position: relative;
  top: 80px;
  left: 100px;
  z-index: 10;
}
.hero .container .row .col-2 .hero-2 img {
  border-radius: 24px;
  width: 500px;
}
.hero .container .row .col-2 .hero-3 {
  position: relative;
  top: 300px;
  right: 10px;
  z-index: 15;
}
.hero .container .row .col-2 .hero-3 img {
  position: absolute;
  bottom: 150px;
  border-radius: 24px;
  width: 250px;
  right: 30px;
}
.hero .container .row .col-2 .hero-3 .pattern-2 {
  position: absolute;
  background-color: #DC5932;
  width: 108px;
  height: 130px;
  bottom: 240px;
  right: 190px;
  border-top-left-radius: 32px;
}
.hero .container .row .col-2 .hero-4 {
  position: relative;
}
.hero .container .row .col-2 .hero-4 .hero-pattern {
  position: absolute;
  background-color: rgba(234, 155, 132, 0.6980392157);
  width: 310px;
  height: 650px;
  top: -390px;
  right: 0;
  border-radius: 0 120px;
}

.emergency-medical-transport {
  padding: 250px 20px;
}
.emergency-medical-transport .container .row {
  gap: 80px;
}
.emergency-medical-transport .container .row .col {
  gap: 24px;
}
.emergency-medical-transport .container .row .col .title {
  display: flex;
  align-items: center;
  gap: 24px;
}
.emergency-medical-transport .container .row .col .title h1 {
  color: #DC5932;
  font-size: 32px;
}
.emergency-medical-transport .container .row .col .about-us-link {
  background-color: #DC5932;
  width: 50%;
  text-align: center;
  color: white;
  border-radius: 32px;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.emergency-medical-transport .container .row .col-2 {
  width: 40%;
}
.emergency-medical-transport .container .row .col-1 {
  width: 50%;
  position: relative;
}
.emergency-medical-transport .container .row .col-1 .img-1 {
  position: absolute;
  left: 0;
  z-index: 10;
  top: -250px;
}
.emergency-medical-transport .container .row .col-1 .img-1 img {
  border-radius: 12px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 290px;
  height: 420px;
}
.emergency-medical-transport .container .row .col-1 .img-2 {
  position: absolute;
  bottom: 8px;
  z-index: 20;
  left: 180px;
  top: -180px;
}
.emergency-medical-transport .container .row .col-1 .img-2 img {
  border-radius: 12px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 270px;
  height: 400px;
}
.emergency-medical-transport .container .row .col-1 .col-pattern {
  position: absolute;
  left: 150px;
  top: -300px;
}
.emergency-medical-transport .container .row .col-1 .col-pattern .pattern {
  background-color: rgba(234, 155, 132, 0.4);
  width: 180px;
  height: 600px;
  border-radius: 0 120px;
}

.assurance {
  padding: 24px 20px;
}
.assurance .container {
  position: relative;
  z-index: 1;
  background: linear-gradient(90deg, rgba(128, 44, 59, 0.6) 0%, rgba(0, 0, 0, 0.618) 90%), url("/public/images/service.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px;
  min-height: 100px;
  border-radius: 24px;
}
.assurance .container p {
  color: white;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  position: relative;
  z-index: 2;
}

.services {
  padding: 50px 20px;
}
.services .container {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.services .container .title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.services .container .row {
  gap: 45px;
}
.services .container .row .col {
  gap: 32px;
}
.services .container .row .col .col-title {
  display: flex;
  gap: 24px;
}
.services .container .row .col img {
  width: 100%;
  height: 450px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
}

.facility-services {
  padding: 24px 20px;
}
.facility-services .container {
  background: linear-gradient(90deg, rgba(128, 44, 59, 0.6) 0%, rgba(0, 0, 0, 0.466) 90%), url("/public/images/MediRydeVans.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 90px;
  border-radius: 12px;
}
.facility-services .container .col {
  gap: 16px;
  text-align: center;
  align-items: center;
}
.facility-services .container .col .link {
  padding-top: 16px;
}
.facility-services .container .col .link a {
  background-color: #DC5932;
  border-radius: 32px;
  padding: 16px 50px;
  color: white;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 10px;
}

.why-section {
  padding: 150px 20px;
}
.why-section .container .row {
  gap: 60px;
  align-items: start;
}
.why-section .container .row .col {
  gap: 24px;
}
.why-section .container .row .col img {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
}
.why-section .container .row .col .title {
  display: flex;
  align-items: center;
  gap: 32px;
}
.why-section .container .row .col .title h1 {
  color: #DC5932;
  font-size: 40px;
}

@media screen and (max-width: 1024px) {
  .hero .container .row .col-2 .hero-2 img {
    width: 400px;
  }
}
@media screen and (max-width: 834px) {
  .hero {
    height: -moz-fit-content;
    height: fit-content;
    padding: 80px 20px;
  }
  .hero .container .row {
    flex-direction: column;
    gap: 60px;
  }
  .hero .container .row .col .contact {
    text-align: center;
  }
  .hero .container .row .col-1 {
    width: 100%;
  }
  .hero .container .row .col-2 {
    width: 100%;
  }
  .hero .container .row .col-2 .hero-1 img {
    top: 10px;
    max-width: 100%;
  }
  .hero .container .row .col-2 .hero-2 img {
    max-width: 100%;
  }
  .hero .container .row .col-2 .hero-3 img {
    right: 100px;
  }
  .hero .container .row .col-2 .hero-3 .pattern-2 {
    right: 250px;
  }
  .hero .container .row .col-2 .hero-4 .hero-pattern {
    top: -300px;
    height: 550px;
  }
  .services .container .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  .services .container .row .col .col-title {
    gap: 12px;
  }
  .services .container .row .col .col-title h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 810px) {
  .hero .container .row .col-1 {
    padding-top: 0;
  }
  .hero .container .row .col-2 {
    display: none;
  }
  .hero .container .row .col-2 .hero-1 img {
    top: 10px;
    max-width: 100%;
  }
  .hero .container .row .col-2 .hero-2 img {
    left: 0;
  }
  .hero .container .row .col-2 .hero-3 img {
    right: 100px;
  }
  .hero .container .row .col-2 .hero-3 .pattern-2 {
    right: 250px;
  }
  .hero .container .row .col-2 .hero-4 .hero-pattern {
    top: -300px;
    height: 550px;
  }
  .emergency-medical-transport {
    margin-bottom: 250px;
    padding: 150px 20px;
  }
  .emergency-medical-transport .container .row {
    flex-direction: column-reverse;
    gap: 60px;
  }
  .emergency-medical-transport .container .row .col-2 {
    width: 100%;
  }
  .emergency-medical-transport .container .row .col-1 {
    width: 100%;
    margin-top: 50px;
  }
  .emergency-medical-transport .container .row .col-1 .img-1 {
    top: -80px;
  }
  .emergency-medical-transport .container .row .col-1 .img-1 img {
    max-height: 100%;
  }
  .emergency-medical-transport .container .row .col-1 .img-2 {
    top: -5px;
  }
  .emergency-medical-transport .container .row .col-1 .col-pattern {
    top: -100px;
  }
  .why-section {
    padding: 50px 20px;
  }
  .why-section .container .row {
    flex-direction: column-reverse;
  }
  .why-section .container .row .col {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .services .container {
    gap: 30px;
  }
  .services .container .row .col {
    gap: 16px;
  }
  .services .container .row .col img {
    height: 100%;
  }
  .services .container .row .col .col-title {
    align-items: center;
  }
  .services .container .row .col .col-title h2 {
    font-size: 18px;
  }
  .services .container .row .col-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .emergency-medical-transport .container .row .col .title h1 {
    font-size: 24px;
  }
  .emergency-medical-transport .container .row .col .about-us-link {
    width: 80%;
  }
  .emergency-medical-transport .container .row .col-1 .img-1 img {
    width: 200px;
  }
  .emergency-medical-transport .container .row .col-1 .img-2 {
    top: 80px;
  }
  .emergency-medical-transport .container .row .col-1 .img-2 img {
    width: 100%;
    height: 300px;
  }
  .emergency-medical-transport .container .row .col-1 .col-pattern .pattern {
    height: 500px;
    width: 150px;
  }
  .facility-services {
    padding: 100px 20px;
  }
  .facility-services .container {
    padding: 50px;
  }
  .why-section .container .row .col .title h1 {
    font-size: 24px;
  }
}/*# sourceMappingURL=home.css.map */