@import "../../variables";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.contact-us {
    // margin-top: 150px;
    margin-bottom: 450px;
    background: rgb(0, 0, 0);
    padding-top: 80px;
    height: 380px;
    padding-inline: 50px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(56, 19, 9, 1) 40%);

    .contact-header {
        text-align: center;
        padding-bottom: 50px;
        
        h3 {
            color: $primary_color;
            font-family: "Outfit", sans-serif;
        }
    }

    .container {
        background-color: $white_color;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
        padding: 40px 32px;

        .row {
            justify-content: space-between;
            gap: 50px;
            .col {
                display: flex;
                flex-direction: column;
                gap: 32px;

                .title-contents {
                    display: flex;
                    gap: $big_gap;

                    .vertical-pattern {
                        background-color: $primary_color;
                        width: 32px;
                        height: 84px;
                    }

                    .title {
                        h1 {
                            color: $brown_color;
                            font-size: 32px;
                        }

                        p {
                            color: $primary_color;
                            font-size: 32px;
                            font-weight: bold;
                        }
                    }
                }

                .desc {
                    font-weight: 600;
                }

                .mail-link {
                    display: flex;
                    gap: $medium_gap;

                    .icon {
                        font-size: 24px;
                    }

                    .mail {
                        font-weight: bold;
                    }
                }

                .social-link {
                    a {
                        background-color: $primary_color;
                        padding: 24px 32px;
                        border-radius: 14px;
                        color: $white_color;
                    }
                }

                .form-title {
                    color: $primary_color;
                }

                form {
                    display: flex;
                    flex-direction: column;
                    gap: 32px;

                    input, textarea {
                        padding: 16px 25px;
                        border: transparent;
                        background-color: #F6F6F6;
                        border-radius: $small_radius;
                    }::placeholder {
                        font-family: "Quicksand", sans-serif;
                        font-weight: 500;
                    }

                    textarea {
                        resize: none;
                    }

                    .send-btn {
                        background-color: $primary_color;
                        border: transparent;
                        border-radius: 24px;
                        padding: 16px 25px;
                        color: $white_color;
                    }
                }
            }

            .col-1 {
                width: 40%;
            }

            .col-2 {
                width: 60%;
            }
        }
    }
}

// responsiveness 
@media screen and (max-width: 540px) {
    .contact-us {
        margin-bottom: 850px;
        padding-inline: 20px;
        .container {
            padding: 20px;

            .row {
                flex-direction: column;

                .col {
                    gap: $medium_gap;
                    width: 100%;

                    .title-contents {
                        .title {
                            h1 {
                                font-size: 24px;
                            }

                            p {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
