@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Quicksand", sans-serif;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
    padding: 12px 24px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
    width: fit-content;
}

.primary-button {
    background-color: $primary_color;
    border-radius: 32px;
    color: $white_color;
}

.container {
    max-width: 1200px;
    padding: 0;
    margin: auto;
}

.hero {
    background-color: $secondary_color;
    height: 90vh;
    max-width: 100%;
}

.row {
    display: flex;
    align-items: center;
}

.col {
    display: flex;
    flex-direction: column;
}

// patterns
.vertical-pattern {
    border-radius: 32px 0px;
}

.title-pattern {
    width: 24px;
    height: 62px;
    border-radius: 32px 0;
    background: rgb(93, 56, 45);
    background: linear-gradient(360deg, rgba(93, 56, 45, 1) 10%, rgba(234, 155, 132, 1) 90%);
}

nav {
    .container {
        padding-inline: 20px;

        .row {
            justify-content: space-between;
        }
    }

    background-color: $secondary_color;
    padding: 20px 0;

    .links {
        display: flex;
        align-items: center;
        gap: $big_gap;

        li {
            a {
                color: $black_color;
                font-weight: bold;
            }

            .active {
                color: $primary_color;
            }

            .underline {
                width: 100%;
                height: 2px;
                background-color: $primary_color;
            }

            .contact-btn {
                background-color: $primary_color;
                padding: 14px 40px;
                border-radius: $big_radius;
                color: $white_color;
            }
        }
    }

    .menu-bar {
        display: none;

        button {
            background: transparent;
            border: transparent;
        }
    }
}

.mobile-menu {
    position: fixed;
    width: 100vw;
    top: 0;
    bottom: 0;
    z-index: 20;
    background-color: $white_color;
    display: flex;
    flex-direction: column;
    gap: $big_gap;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $secondary_color;
        padding: 16px 20px;

        .logo {
            img {
                width: 54px;
            }
        }

        .menu-bar {
            display: block;

            button {
                font-size: 24px;
            }
        }
    }

    .links-container {
        text-align: center;

        .mobile-links {
            display: flex;
            flex-direction: column;
            gap: $gap;
            padding-inline: 20px;

            li {
                background-color: #fcf9f9;
                padding: 16px 50px;
                border-radius: 8px;

                a {
                    color: #000;
                }

                .active {
                    color: $primary_color;
                }
            }
        }
    }
}

.page-header {
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(56, 19, 9, 1) 40%);
    color: $primary_color;
    text-align: center;
    padding: 48px;

    h3 {
        font-size: 32px;
    }

    h4 {
        color: $white_color;
    }

    .facility-services-desc {
        color: $white_color;
    }
}

footer {
    display: flex;
    flex-direction: column;
    gap: $medium_gap;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(56, 19, 9, 1) 40%);

    .container {
        color: $white_color;
        padding: 20px;

        .footer-row {
            display: flex;
            align-items: start;
            justify-content: space-between;
            gap: 150px;

            .col {
                display: flex;
                flex-direction: column;
                gap: $medium_gap;

                h2 {
                    font-size: 18px;
                }

                .facebook-link {
                    background-color: $primary_color;
                    color: $white_color;
                    padding: 24px 32px;
                    border-radius: 14px;
                }

                .links {
                    display: flex;
                    gap: $big_gap;

                    .link {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;

                        a {
                            color: $white_color;
                        }
                    }
                }

                .col-row {
                    display: flex;
                    gap: $big_gap;

                    .icon {
                        font-size: 24px;
                    }
                }

                .col-row-2 {
                    display: flex;
                    gap: $big_gap;
                    align-items: center;

                    .icon {
                        font-size: 24px;
                    }

                    .desc {
                        display: flex;
                        flex-direction: column;
                        // gap: 12px;

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .underline {
        background-color: #57575752;
        width: 100%;
        height: 2px;
    }

    .copyright {
        padding-inline: 20px;
        padding-bottom: 20px;

        p {
            max-width: 1200px;
            margin: auto;
            color: $white_color;
        }
    }
}

// responsiveness
@media screen and (max-width: 1024px) {
    footer {
        .container {
            .footer-row {
                gap: 50px;
            }
        }
    }
}

// responsiveness
@media screen and (max-width: 768px) {
    nav {
        .container {
            .links {
                display: none;
            }

            .menu-bar {
                display: block;

                button {
                    font-size: 32px;
                }
            }
        }
    }

    footer {
        .container {
            .footer-row {
                display: flex;
                flex-wrap: wrap;

                .col {
                    .facebook-link {
                        padding: 24px 6px;
                        text-align: center;
                    }
                }
            }
        }

        .copyright {
            p {
                font-size: 14px;
            }
        }
    }
}