@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.testimonials .header {
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(56, 19, 9) 40%);
  text-align: center;
  padding: 48px;
  height: 45vh;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
}
.testimonials .header h3 {
  color: white;
}
.testimonials .header p {
  color: #DC5932;
}
.testimonials .container {
  padding-inline: 20px;
}
.testimonials .container .testimonials-container {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 20px 24px;
  position: relative;
  overflow: hidden;
  height: 490px;
  width: 100%;
  background-color: white;
  border-radius: 24px;
  box-shadow: 2px 5px rgba(233, 233, 233, 0.2509803922);
  top: -180px;
}
.testimonials .container .testimonials-container article {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-inline: 80px;
  opacity: 0;
  text-align: center;
  transition: all 0.3s linear;
}
.testimonials .container .testimonials-container article p {
  padding-inline: 150px;
  color: #484848;
}
.testimonials .container .testimonials-container article .pattern-container {
  text-align: center;
  display: flex;
  justify-content: center;
}
.testimonials .container .testimonials-container article .pattern-container .pattern {
  text-align: center;
  width: 54px;
  height: 16px;
  background-color: #DC5932;
  border-radius: 32px 0;
}
.testimonials .container .testimonials-container article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
.testimonials .container .testimonials-container article.lastSlide {
  transform: translateX(-100%);
}
.testimonials .container .testimonials-container article.nextSlide {
  transform: translateX(100%);
}
.testimonials .container .testimonials-container .prev,
.testimonials .container .testimonials-container .next {
  position: absolute;
  font-size: 20px;
  color: #DC5932;
  background-color: #FFEEEA;
  width: 60px;
  height: 60px;
  padding: 20px;
  border: transparent;
  border-radius: 50%;
}
.testimonials .container .testimonials-container .prev {
  left: 50px;
}
.testimonials .container .testimonials-container .next {
  right: 50px;
}

@media screen and (max-width: 820px) {
  .testimonials .container .testimonials-container {
    top: -280px;
    gap: 20px;
  }
  .testimonials .container .testimonials-container article p {
    padding-inline: 20px;
  }
  .testimonials .container .testimonials-container .prev {
    left: 10px;
  }
  .testimonials .container .testimonials-container .next {
    right: 10px;
  }
}
@media screen and (max-width: 540px) {
  .testimonials .container {
    padding-inline: 10px;
  }
  .testimonials .container .testimonials-container {
    top: -150px;
    gap: 10px;
    height: 280px;
    display: flex;
    justify-content: center;
  }
  .testimonials .container .testimonials-container article {
    font-size: 14px;
    padding-inline: 20px;
  }
  .testimonials .container .testimonials-container article p {
    padding-inline: 10px;
  }
  .testimonials .container .testimonials-container .prev, .testimonials .container .testimonials-container .next {
    background: transparent;
  }
  .testimonials .container .testimonials-container .prev {
    left: 0;
  }
  .testimonials .container .testimonials-container .next {
    right: 0;
  }
}/*# sourceMappingURL=testimonials.css.map */