@import "./variables.scss";

.list-services {
    display: grid;
    place-items: center;
    background-color: $secondary_color;

    .list-service {
        background-color: $white_color;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: $gap;
        position: fixed;
        left: 650px;
        top: 90px;
        z-index: 90;
        border-radius: $medium_radius;

        .service {
            background-color: $secondary_color;
            padding: 14px 16px;
            border-radius: $medium_radius;

            a {
                color: $black_color;
                font-weight: 600;
            }
        }
    }
}
