@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.contact-us {
  margin-bottom: 450px;
  background: rgb(0, 0, 0);
  padding-top: 80px;
  height: 380px;
  padding-inline: 50px;
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(56, 19, 9) 40%);
}
.contact-us .contact-header {
  text-align: center;
  padding-bottom: 50px;
}
.contact-us .contact-header h3 {
  color: #DC5932;
  font-family: "Outfit", sans-serif;
}
.contact-us .container {
  background-color: white;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  padding: 40px 32px;
}
.contact-us .container .row {
  justify-content: space-between;
  gap: 50px;
}
.contact-us .container .row .col {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.contact-us .container .row .col .title-contents {
  display: flex;
  gap: 24px;
}
.contact-us .container .row .col .title-contents .vertical-pattern {
  background-color: #DC5932;
  width: 32px;
  height: 84px;
}
.contact-us .container .row .col .title-contents .title h1 {
  color: #802C3B;
  font-size: 32px;
}
.contact-us .container .row .col .title-contents .title p {
  color: #DC5932;
  font-size: 32px;
  font-weight: bold;
}
.contact-us .container .row .col .desc {
  font-weight: 600;
}
.contact-us .container .row .col .mail-link {
  display: flex;
  gap: 16px;
}
.contact-us .container .row .col .mail-link .icon {
  font-size: 24px;
}
.contact-us .container .row .col .mail-link .mail {
  font-weight: bold;
}
.contact-us .container .row .col .social-link a {
  background-color: #DC5932;
  padding: 24px 32px;
  border-radius: 14px;
  color: white;
}
.contact-us .container .row .col .form-title {
  color: #DC5932;
}
.contact-us .container .row .col form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.contact-us .container .row .col form input, .contact-us .container .row .col form textarea {
  padding: 16px 25px;
  border: transparent;
  background-color: #F6F6F6;
  border-radius: 8px;
}
.contact-us .container .row .col form ::-moz-placeholder {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
}
.contact-us .container .row .col form ::placeholder {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
}
.contact-us .container .row .col form textarea {
  resize: none;
}
.contact-us .container .row .col form .send-btn {
  background-color: #DC5932;
  border: transparent;
  border-radius: 24px;
  padding: 16px 25px;
  color: white;
}
.contact-us .container .row .col-1 {
  width: 40%;
}
.contact-us .container .row .col-2 {
  width: 60%;
}

@media screen and (max-width: 540px) {
  .contact-us {
    margin-bottom: 850px;
    padding-inline: 20px;
  }
  .contact-us .container {
    padding: 20px;
  }
  .contact-us .container .row {
    flex-direction: column;
  }
  .contact-us .container .row .col {
    gap: 16px;
    width: 100%;
  }
  .contact-us .container .row .col .title-contents .title h1 {
    font-size: 24px;
  }
  .contact-us .container .row .col .title-contents .title p {
    font-size: 20px;
  }
}/*# sourceMappingURL=contact.css.map */