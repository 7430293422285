@import '../../variables';

.popup {
    background-color: rgba(0, 0, 0, 0.381);
    position: fixed;
    width: 100vw;
    top: 0;
    bottom: 0;
    z-index: 10;
    display: grid;
    place-items: center;
    padding-inline: 90px;

    .popup-contents {
        background-color: $white_color;
        padding: 40px 32px;
        display: flex;
        flex-direction: column;
        gap: $gap;
        border-radius: 10px;

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                font-size: 20px;
            }

            button {
                border: transparent;
                background: transparent;
                font-size: 24px;
            }
        }

        .position-details {
            display: flex;
            flex-direction: column;
            gap: $big_gap;

            h1 {
                font-size: 24px;
            }

            p {
                color: #595252;
            }

            .link {
                padding-top: 20px;
                .apply-link {
                    background-color: $primary_color;
                    padding: 10px 32px;
                    color: $white_color;
                    border-radius: $medium_radius;
                }
            }
        }
    }
}
.careers {
    padding-bottom: 130px;
    
    .container {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 100px 20px;

        .title {
            display: flex;
            gap: $big_gap;
            align-items: center;
            justify-content: center;

            h1 {
                color: $primary_color;
                font-size: 32px;
            }
        }

        .open-positions-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: $gap;

            .open-positions{
                
                .position {
                    background-color: #FAFAFA;
                    padding: 24px;
                    display: flex;
                    flex-direction: column;
                    gap: $gap;
                    border-radius: $medium_radius;

                    .position-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        h1 {
                            font-size: 20px;
                        }

                        p {
                            color: $primary_color;
                            background-color: #FCF6F7;
                            border-radius: 8px;
                            padding: 7px 24px;
                            font-size: 16px;
                        }
                    }

                    .details-link {
                        background-color: #FDEEEA;
                        color: $primary_color;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: $gap;
                        border-radius: $medium_radius;
                        padding: 10px 32px;
                        width: 40%;
                        font-weight: bold;
                        border: transparent;
                    }
                }
            }

        }

    }
}

// responsiveness
@media screen and (max-width: 820px) {
    .careers {
        .container {
            .open-positions-container {
                .open-positions {
                    .position {
                        .position-title {
                            flex-direction: column;
                            align-items: start;
                            gap: $medium_radius;                            
                        }
                        .details-link {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .careers {
        .container {
            .title {
                h1 {
                    font-size: 24px;
                }
            }

            .open-positions-container {
                display: flex;
                flex-direction: column;
                gap: $big_gap;

                .open-positions {
                    .position {
                        .details-link {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}