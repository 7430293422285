@import "../../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.testimonials {
    .header {
        background: rgb(0, 0, 0);
        background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(56, 19, 9, 1) 40%);
        text-align: center;
        padding: 48px;
        height: 45vh;
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-family: "Outfit", sans-serif;
        font-size: 18px;

        h3 {
            color: $white_color;
        }

        p {
            color: $primary_color;
        }
    }
    .container {
        padding-inline: 20px;
        .testimonials-container {
            display: flex;
            align-items: center;
            gap: $big_gap;
            padding: 20px 24px;
            position: relative;
            overflow: hidden;
            height: 490px;
            width: 100%;
            background-color: $white_color;
            border-radius: 24px;
            box-shadow: 2px 5px #e9e9e940;
            top: -180px;

            article {
                position: absolute;;
                display: flex;
                flex-direction: column;
                gap: $big_gap;
                padding-inline: 80px;
                opacity: 0;
                text-align: center;
                transition: all 0.3s linear;

                p {
                    padding-inline: 150px;
                    color: #484848;
                }

                .pattern-container {
                    text-align: center;
                    display: flex;
                    justify-content: center;

                    .pattern {
                        text-align: center;
                        width: 54px;
                        height: 16px;
                        background-color: $primary_color;
                        border-radius: 32px 0;
                    }
                }
            }

            article.activeSlide {
                opacity: 1;
                transform: translateX(0);
            }

            article.lastSlide {
                transform: translateX(-100%);
            }

            article.nextSlide {
                transform: translateX(100%);
            }

            .prev,
            .next {
                position: absolute;
                font-size: 20px;
                color: $primary_color;
                background-color: #FFEEEA;
                width: 60px;
                height: 60px;
                padding: 20px;
                border: transparent;
                border-radius: 50%;
            }

            .prev {
                left: 50px;
            }

            .next {
                right: 50px;
            }
        }
    }
}

// responsiveness
@media screen and (max-width: 820px) {
    .testimonials {
        .container {
            .testimonials-container {
                top: -280px;
                gap: 20px;

                article {
                    p {
                        padding-inline: 20px;
                    }
                }

                .prev {
                    left: 10px;
                }

                .next {
                    right: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .testimonials {
        .container {
            padding-inline: 10px;
            .testimonials-container {
                top: -150px;
                gap: 10px;
                height: 280px;
                display: flex;
                justify-content: center;

                article {
                    font-size: 14px;
                    padding-inline: 20px;
                    p {
                        padding-inline: 10px;
                    }
                }

                .prev, .next {
                    background: transparent;
                }

                .prev {
                    left: 0;
                }

                .next {
                    right: 0;
                }
            }
        }
    }
}
