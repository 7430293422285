.services-container {
  padding: 0;
}
.services-container .container {
  padding-inline: 40px;
}
.services-container .container .row,
.services-container .container .row-1,
.services-container .container .row-2 {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
.services-container .container .row .row-1 .title-pattern {
  height: 90px;
  width: 20px;
}
.services-container .container .row .row-2 .title-pattern {
  height: 30px;
  width: 20px;
}
.services-container .container > .row {
  padding-top: 100px;
  padding-bottom: 30px;
  gap: 70px;
}
.services-container .container > .row .img-container {
  width: 50%;
  border-radius: 24px;
  overflow: hidden;
}
.services-container .container > .row .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.services-container .container > .row .text-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.services-container .container > .row .text-content p {
  font-size: 18px;
  line-height: 30px;
}
.services-container .container > .row .text-content .row {
  gap: 20px;
  align-items: center;
}
.services-container .container > .row .text-content .row h1 {
  color: #DC5932;
  font-size: 32px;
  font-weight: 500;
}

@media screen and (max-width: 810px) {
  .services-container .container {
    padding-block: 100px;
    padding-inline: 20px;
  }
  .services-container .container > .row {
    padding-block: 0;
    padding-block: 25px;
    flex-direction: column;
  }
  .services-container .container > .row .img-container {
    width: 100%;
  }
  .services-container .container > .row .text-content {
    width: 100%;
  }
  .services-container .container .img-left-service {
    flex-direction: column-reverse;
  }
}/*# sourceMappingURL=services.css.map */