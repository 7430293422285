@import "../../variables";

.about-us {
    
    .container {
        display: flex;
        flex-direction: column;
        gap: 60px;

        .row {
            gap: 180px;
            justify-content: space-between;
            padding: 80px 20px;

            .col {
                gap: 24px;

                h1 {
                    color: $primary_color;
                }

                .title {
                    display: flex;
                    align-items: center;
                    gap: $big_gap;

                    .icon {
                        font-size: 24px;
                    }

                    h3 {
                        color: $primary_color;
                    }

                    h1 {
                        color: $primary_color;
                    }

                    p {
                        font-weight: bold;
                    }
                }

                .services-container {
                    display: flex;
                    gap: 60px;

                    .services-contents {
                        display: flex;
                        align-items: start;
                        gap: $big_gap;

                        .pattern {
                            width: 15px;
                            height: 150px;
                            border-radius: 32px 0;
                            background: rgb(93, 56, 45);
                            background: linear-gradient(360deg, rgba(93, 56, 45, 1) 10%, rgba(234, 155, 132, 1) 90%);
                        }

                        .service {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                        }
                    }
                }

                .location, .phone-number {
                    font-weight: bold;
                    color: #4D4D4D;
                }
            }

            .col-1 {
                width: 80%;
                position: relative;

                .img-1 {
                    position: absolute;
                    left: 0;
                    z-index: 10;
                    top: -250px;

                    img {
                        border-radius: 12px;
                        object-fit: cover;
                        width: 290px;
                        height: 420px;
                    }
                }

                .img-2 {
                    position: absolute;
                    bottom: 8px;
                    z-index: 20;
                    left: 180px;
                    top: -180px;

                    img {
                        border-radius: 12px;
                        object-fit: cover;
                        width: 270px;
                        height: 400px;
                    }
                }

                .col-pattern {
                    position: absolute;
                    left: 150px;
                    top: -300px;

                    .pattern {
                        background-color: #ea9b8466;
                        width: 180px;
                        height: 600px;
                        border-radius: 0 120px;
                    }
                }
            }

            .col-2 {
                width: 20%;
            }

            .col-img {
                background-color: #F9F9F9;
                padding: 20px;
            }
        }

        .row-1 {
            padding-top: 180px;
        }
    }
}

// responsiveness 
@media screen and (max-width: 1024px) {
    .about-us {
        .container {
            .row {
                gap: 250px;
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .about-us {
        .container {
            display: flex;
            flex-direction: column;
            gap: 150px;
            .row {
                gap: 80px;

                .col-1 {

                    .img-1 {
                        top: -150px;
                    }

                    .img-2 {
                        top: -50px;
                    }

                    .col-pattern {
                        top: -200px;
                    }
                }
            }
            .row-1 {
                flex-direction: column-reverse;
                gap: 180px;
                padding-top: 80px;
            }

            
        }
    }
}

@media screen and (max-width: 850px){
    .about-us {
        .container {
            .row-2 {
                flex-direction: column;

                .col {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .about-us {
        .container {
            gap: 8px;
            .row {
                .col {
                    .services-container {
                        .services-contents {
                            .pattern {
                                height: 190px;
                            }
                            .service {
                                p {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
            .row-1 {
                .col-1 {
                    display: none;
                }
            }
        }
    }
}
