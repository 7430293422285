.list-services {
  display: grid;
  place-items: center;
  background-color: #FDF7F5;
}
.list-services .list-service {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  left: 650px;
  top: 90px;
  z-index: 90;
  border-radius: 12px;
}
.list-services .list-service .service {
  background-color: #FDF7F5;
  padding: 14px 16px;
  border-radius: 12px;
}
.list-services .list-service .service a {
  color: #000000;
  font-weight: 600;
}/*# sourceMappingURL=serviceslist.css.map */