@import "../../variables";

.faqs {
  .container {
    padding-top: 100px;
    padding-bottom: 30px;
    padding-inline: 50px;
    .accordion {
      .main-accordion {
        display: flex;
        gap: 50px;
        justify-content: space-between;
        ul {
          width: 50%;

          display: flex;
          flex-direction: column;
          gap: 30px;

          > div {
            width: 100%;
          }

          li {
            display: flex;
            gap: 30px;
            cursor: pointer;
            align-items: center;
            width: 100%;

            h1 {
              font-size: 18px;
              font-weight: 500;
            }

            .plus-icon,
            .minus-icon {
              height: 30px;
              width: 30px;
              display: block;
              cursor: pointer;
            }
          }
        }
        .accordion-content {
          width: 50%;
        }
      }
    }
  }
}

.accordion-active {
  color: $primary_color;
}

.accordion-content-mobile {
  display: none;
}

.accordion-content-desktop {
  display: block;
}

@media screen and (max-width: 810px) {
  .faqs {
    .container {
      padding-inline: 20px;
      .accordion {
        .main-accordion {
          ul {
            width: 100%;
            li {
              h1 {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .accordion-content-mobile {
    display: block;
  }
  .accordion-content-desktop {
    display: none;
  }
}
