.popup {
  background-color: rgba(0, 0, 0, 0.381);
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  z-index: 10;
  display: grid;
  place-items: center;
  padding-inline: 90px;
}
.popup .popup-contents {
  background-color: white;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
}
.popup .popup-contents .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup .popup-contents .title h3 {
  font-size: 20px;
}
.popup .popup-contents .title button {
  border: transparent;
  background: transparent;
  font-size: 24px;
}
.popup .popup-contents .position-details {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.popup .popup-contents .position-details h1 {
  font-size: 24px;
}
.popup .popup-contents .position-details p {
  color: #595252;
}
.popup .popup-contents .position-details .link {
  padding-top: 20px;
}
.popup .popup-contents .position-details .link .apply-link {
  background-color: #DC5932;
  padding: 10px 32px;
  color: white;
  border-radius: 12px;
}

.careers {
  padding-bottom: 130px;
}
.careers .container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 100px 20px;
}
.careers .container .title {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
}
.careers .container .title h1 {
  color: #DC5932;
  font-size: 32px;
}
.careers .container .open-positions-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.careers .container .open-positions-container .open-positions .position {
  background-color: #FAFAFA;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 12px;
}
.careers .container .open-positions-container .open-positions .position .position-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.careers .container .open-positions-container .open-positions .position .position-title h1 {
  font-size: 20px;
}
.careers .container .open-positions-container .open-positions .position .position-title p {
  color: #DC5932;
  background-color: #FCF6F7;
  border-radius: 8px;
  padding: 7px 24px;
  font-size: 16px;
}
.careers .container .open-positions-container .open-positions .position .details-link {
  background-color: #FDEEEA;
  color: #DC5932;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 12px;
  padding: 10px 32px;
  width: 40%;
  font-weight: bold;
  border: transparent;
}

@media screen and (max-width: 820px) {
  .careers .container .open-positions-container .open-positions .position .position-title {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
  .careers .container .open-positions-container .open-positions .position .details-link {
    width: 60%;
  }
}
@media screen and (max-width: 540px) {
  .careers .container .title h1 {
    font-size: 24px;
  }
  .careers .container .open-positions-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .careers .container .open-positions-container .open-positions .position .details-link {
    width: 100%;
  }
}/*# sourceMappingURL=careers.css.map */