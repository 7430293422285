.about-us .container {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.about-us .container .row {
  gap: 180px;
  justify-content: space-between;
  padding: 80px 20px;
}
.about-us .container .row .col {
  gap: 24px;
}
.about-us .container .row .col h1 {
  color: #DC5932;
}
.about-us .container .row .col .title {
  display: flex;
  align-items: center;
  gap: 24px;
}
.about-us .container .row .col .title .icon {
  font-size: 24px;
}
.about-us .container .row .col .title h3 {
  color: #DC5932;
}
.about-us .container .row .col .title h1 {
  color: #DC5932;
}
.about-us .container .row .col .title p {
  font-weight: bold;
}
.about-us .container .row .col .services-container {
  display: flex;
  gap: 60px;
}
.about-us .container .row .col .services-container .services-contents {
  display: flex;
  align-items: start;
  gap: 24px;
}
.about-us .container .row .col .services-container .services-contents .pattern {
  width: 15px;
  height: 150px;
  border-radius: 32px 0;
  background: rgb(93, 56, 45);
  background: linear-gradient(360deg, rgb(93, 56, 45) 10%, rgb(234, 155, 132) 90%);
}
.about-us .container .row .col .services-container .services-contents .service {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about-us .container .row .col .location, .about-us .container .row .col .phone-number {
  font-weight: bold;
  color: #4D4D4D;
}
.about-us .container .row .col-1 {
  width: 80%;
  position: relative;
}
.about-us .container .row .col-1 .img-1 {
  position: absolute;
  left: 0;
  z-index: 10;
  top: -250px;
}
.about-us .container .row .col-1 .img-1 img {
  border-radius: 12px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 290px;
  height: 420px;
}
.about-us .container .row .col-1 .img-2 {
  position: absolute;
  bottom: 8px;
  z-index: 20;
  left: 180px;
  top: -180px;
}
.about-us .container .row .col-1 .img-2 img {
  border-radius: 12px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 270px;
  height: 400px;
}
.about-us .container .row .col-1 .col-pattern {
  position: absolute;
  left: 150px;
  top: -300px;
}
.about-us .container .row .col-1 .col-pattern .pattern {
  background-color: rgba(234, 155, 132, 0.4);
  width: 180px;
  height: 600px;
  border-radius: 0 120px;
}
.about-us .container .row .col-2 {
  width: 20%;
}
.about-us .container .row .col-img {
  background-color: #F9F9F9;
  padding: 20px;
}
.about-us .container .row-1 {
  padding-top: 180px;
}

@media screen and (max-width: 1024px) {
  .about-us .container .row {
    gap: 250px;
  }
}
@media screen and (max-width: 950px) {
  .about-us .container {
    display: flex;
    flex-direction: column;
    gap: 150px;
  }
  .about-us .container .row {
    gap: 80px;
  }
  .about-us .container .row .col-1 .img-1 {
    top: -150px;
  }
  .about-us .container .row .col-1 .img-2 {
    top: -50px;
  }
  .about-us .container .row .col-1 .col-pattern {
    top: -200px;
  }
  .about-us .container .row-1 {
    flex-direction: column-reverse;
    gap: 180px;
    padding-top: 80px;
  }
}
@media screen and (max-width: 850px) {
  .about-us .container .row-2 {
    flex-direction: column;
  }
  .about-us .container .row-2 .col {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .about-us .container {
    gap: 8px;
  }
  .about-us .container .row .col .services-container .services-contents .pattern {
    height: 190px;
  }
  .about-us .container .row .col .services-container .services-contents .service p {
    font-size: 14px;
  }
  .about-us .container .row-1 .col-1 {
    display: none;
  }
}/*# sourceMappingURL=about.css.map */